<template>
  <div>
    <v-table>
      <tbody>
        <tr>
          <td>AF 057 Air France</td>
          <td><a href="https://airfrancemx.getyourinvoice.com/airline/AF" target="_blank">https://airfrancemx.getyourinvoice.com/airline/AF</a></td>
          <td>mail.facturaelectronica@airfrance.fr<br><a href="https://www.afkl.biz/" target="_blank">https://www.afkl.biz/</a></td>
        </tr>
        <tr>
          <td>CX 160 Cathy</td>
          <td><a href="https://webportal.edicomgroup.com/customers/cathaypacific/pasaje/search.htm" target="_blank">https://webportal.edicomgroup.com/customers/cathaypacific/pasaje/search.htm</a></td>
          <td></td>
        </tr>
        <tr>
          <td>CM 230 Copa</td>
          <td><a href="https://copamx.getyourinvoice.com/airline/CO" target="_blank">https://copamx.getyourinvoice.com/airline/CO</a></td>
          <td></td>
        </tr>
        <tr>
          <td>KL 074 KLM</td>
          <td><a href="https://airfrancemx.getyourinvoice.com/information/KL" target="_blank">https://airfrancemx.getyourinvoice.com/information/KL</a></td>
          <td>mail.facturaelectronica@airfrance.fr<br/><a href="https://www.afkl.biz/" target="_blank">https://www.afkl.biz/</a></td>
        </tr>
        <tr>
          <td>DL 006 Delta</td>
          <td><a href="https://webportal.edicomgroup.com/customers/delta/pasaje/search.htm" target="_blank">https://webportal.edicomgroup.com/customers/delta/pasaje/search.htm</a></td>
          <td>mail.facturaelectronica@airfrance.fr<br/><a href="https://www.afkl.biz/" target="_blank">https://www.afkl.biz/</a></td>
        </tr>
        <tr>
          <td>LH 220 Lufthansa</td>
          <td><a href="https://webportal.edicomgroup.com/customers/lufthansa/pasaje/search.htm" target="_blank">https://webportal.edicomgroup.com/customers/lufthansa/pasaje/search.htm</a></td>
          <td>Lufthansa</td>
        </tr>
        <tr>
          <td>EK 176 Emirates</td>
          <td><a href="" target="_blank"></a></td>
          <td>Se tiene que pedir que se facture por boleto separado, si no, los facturan todo juntos <br/> facturasmexico@emirates.com</td>
        </tr>
        <tr>
          <td>HR 169 Hahn Air</td>
          <td><a href="https://webportal.edicomgroup.com/customers/hahnair/search.htm" target="_blank">https://webportal.edicomgroup.com/customers/hahnair/search.htm</a></td>
          <td class="red-danger">NO TIMBRA ANTE SAT</td>
        </tr>
        <tr>
          <td>TK 235 Turkish Airline</td>
          <td><a href="" target="_blank"></a></td>
          <td>Solo facturan boleto con origien de Mexico (que lleva impuesto XO) <br/> mexfacturacion@thy.com</td>
        </tr>
        <tr>
          <td>UX 996 Air Europa</td>
          <td><a href="https://webportal.edicomgroup.com/customers/aireuropa/pasaje/search.htm" target="_blank">https://webportal.edicomgroup.com/customers/aireuropa/pasaje/search.htm</a></td>
          <td></td>
        </tr>

        <tr>
          <td>UA 016 United</td>
          <td><a href="https://webportal.edicomgroup.com/customers/united/pasaje/search.htm" target="_blank">https://webportal.edicomgroup.com/customers/united/pasaje/search.htm</a></td>
          <td></td>
        </tr>

        <tr>
          <td>IB 075 Iberia</td>
          <td><a href="https://webportal.edicomgroup.com/customers/iberia/pasaje/search.htm" target="_blank">https://webportal.edicomgroup.com/customers/iberia/pasaje/search.htm</a></td>
          <td></td>
        </tr>
        <tr>
          <td>B6 279 JetBlue</td>
          <td><a href="https://webportal.edicomgroup.com/customers/jetblue/pasaje/search.htm" target="_blank">https://webportal.edicomgroup.com/customers/jetblue/pasaje/search.htm</a></td>
          <td></td>
        </tr>
      </tbody>
    </v-table>
  </div>
</template>

<script>
export default {
  name: 'InvoicingInfo',
  data() {
    return {
      // Define your component data here
    };
  },
  methods: {
    // Define your component methods here
  },
  computed: {
    // Define your computed properties here
  },
};
</script>

<style scoped>
/* Add your CSS styles here */
</style>