import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import store from './store';
import axios from 'axios';

// Axios interceptor
axios.interceptors.request.use(
  (config) => {
    store.commit('setIsLoading', true);
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  (error) => {
    store.commit('setIsLoading', false);
    return Promise.reject(error);
  }
);


axios.interceptors.response.use(
  (response) => {
    store.commit('setIsLoading', false);
    return response;
  }, (error) => {
    store.commit('setIsLoading', false);
    if (error.response && error.response.status === 401) {
      // Unset token and redirect to login page
      localStorage.removeItem('token');
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);


// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import '@mdi/font/css/materialdesignicons.css'

const vuetify = createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: 'light',
    //
  },
})

const app = createApp(App);
app.config.globalProperties.$filters = {
  toLocalTime(value) {
    if (!value) return '';
    const date = new Date(value);
    return date.toLocaleString();
  },
  toMexicoCityTime(value) {
    if (!value) return '';
    const date = new Date(value);
    return date.toLocaleString('es-MX', { timeZone: 'America/Mexico_City' });
  }
};

app.use(store).use(router).use(vuetify).mount('#app')
